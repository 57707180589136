<template>
  <nav class="app-tabs">
    <transition-group class="app-tabs__tab-group" tag="div" name="list">
      <app-tab
        v-for="(tab, index) in tabs"
        :title="tab.title"
        :selected="selected === tab.id"
        :icons="tab.icons"
        :height="height"
        :breadcrumbs="tab.breadcrumbs"
        :draggable="enableExperimentalDragAndDropSort"
        :closable="closable"
        :disable-tooltip="disableTooltip"
        @click="emit('click', tab.id)"
        @close="close(tab)"
        @dragstart="dragstart(index)"
        @dragover.prevent
        @drop="moveTabToNewPosition(index)"
        :id="tab.id"
        :key="tab.id"
      >
        <template v-slot:tooltip-bubble>
          <slot name="tooltip-bubble" :id="tab.id" />
        </template>
      </app-tab>
    </transition-group>
    <span class="app-tabs__content-after">
      <slot name="content-after" />
    </span>
  </nav>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

import { ButtonSize } from "@horizon56/styles/types";

import AppTab, { Tab } from "@/components/app-tabs/app-tab.vue";
import { moveTab } from "@/components/app-tabs/utils/helpers";

export type Tabs = {
  tabs: Array<Tab>;
  selected?: Tab["id"];
};

const props = withDefaults(
  defineProps<
    Tabs & {
      closable?: boolean;
      disableTooltip?: boolean;
      height?: ButtonSize;
      enableExperimentalDragAndDropSort?: boolean;
    }
  >(),
  {
    closable: true,
    height: "medium",
  },
);

const emit = defineEmits<{
  (e: "click", tab: string): void;
  (e: "close", tab: string): void;
  (e: "sort", tabs: string[]): void;
}>();

const tabs = ref(props.tabs);
watch(
  () => props.tabs,
  (newTabs) => {
    tabs.value = newTabs;
  },
);

let draggedIndex: number;
const dragstart = (index: number) => (draggedIndex = index);

const moveTabToNewPosition = (newIndex: number) => {
  emit(
    "sort",
    moveTab(tabs.value, draggedIndex, newIndex).map((t) => t.id),
  );
};

const close = (tab: Tab) => {
  emit("close", tab.id);
};
</script>

<style scoped lang="scss">
.app-tabs {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  background-color: var(--menu-bg); // inactive-bg
  &__tab-group {
    display: flex;
    flex-grow: 1;
  }
  &__content-after:not(:empty) {
    margin-left: auto;
    padding: 0 var(--app-input-spacing-small);
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
  }
}
</style>

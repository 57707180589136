<template>
  <Tooltip
    :delay="0.01"
    :disabled="selected || disableTooltip"
    :aria-label="title"
    :aria-current="selected"
    tabindex="0"
    class="app-tab"
    @click="emit('click')"
    @keydown.enter="emit('click')"
    v-class-mod:app-tab="[{ selected }, `button-height-${height}`]"
  >
    <span class="app-tab__content">
      <IconWrapper
        v-for="(icon, i) in icons"
        :icon="icon.name"
        :color="icon.color"
        :aria-label="`${icon.description},`"
        class="app-tab__status-icon"
        :key="i"
      />
      <span class="app-tab__title">
        <cut-text :text="title" />
      </span>
    </span>
    <icon-toggle-button
      v-if="closable"
      :tabindex="0"
      :title="translations.tabs.close"
      :aria-label="interpolate(translations.tabs.closeSpecific, { n: title })"
      :action="
        (e) => {
          e.stopPropagation();
          emit('close');
        }
      "
      class="app-tab__close-button"
      icon="close"
      button-size="medium"
    />
  </Tooltip>
</template>

<script lang="ts" setup>
import { IconName } from "@horizon56/fonts/types";
import { ButtonSize, ColorName } from "@horizon56/styles/types";
import { interpolate } from "@horizon56/translations";

import { translations } from "@/infrastructure/translations";

import IconToggleButton from "@/components/buttons/icon-toggle-button.vue";
import CutText from "@/components/content/cut-text.vue";
import IconWrapper from "@/components/icons/icon-wrapper.vue";
import Tooltip from "@/components/tooltips/tool-tip.vue";

export type Tab = {
  id: string;
  title: string;
  icons?: Array<IconInfo>;
  breadcrumbs?: Array<string>;
};
export type IconInfo = {
  name: IconName;
  description?: string;
  color?: ColorName;
};

defineProps<{
  title: string;
  selected?: boolean;
  height?: ButtonSize;
  disableTooltip?: boolean;
  closable?: boolean;
  icons?: Array<IconInfo>;
  breadcrumbs?: Array<string>;
}>();

const emit = defineEmits<{
  (e: "click"): void;
  (e: "close"): void;
}>();
</script>

<style scoped lang="scss">
// Background
$active-bg-color: var(--card-bg);
// Foreground
$active-color: var(--black-90);
$inactive-color: var(--black-50);

.app-tab {
  $block: &;
  display: flex;
  flex: 1;
  cursor: pointer;
  user-select: none;
  max-width: var(--app-tabs-default-width);
  padding: 0 4px;
  height: var(--app-button-height-medium);
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-right: 1px solid var(--black-20);
  background-color: var(--menu-bg);
  @include focus-outline();

  &:last-child {
    border-right: none;
    &:after {
      right: -1px;
      content: "";
      height: 100%;
      width: 1px;
      position: absolute;
      background-color: var(--black-20);
    }
  }

  @each $size in $buttonSizes {
    &--button-height-#{$size} {
      height: var(--app-button-height-#{$size});
      @if ($size == large) {
        padding: 0 8px;
      }
    }
  }

  &--selected {
    background-color: $active-bg-color;

    #{$block}__status-icon {
      filter: unset;
      color: $active-color;
    }

    #{$block}__title {
      color: $active-color;
    }
  }

  &__close-button {
    margin-left: auto;
  }

  &__status-icon {
    color: $inactive-color;
    font-size: var(--app-icon-size-medium);
  }
  &__title {
    color: $inactive-color;
    font-size: var(--app-font-size-label);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__content {
    flex: 1;
    background: unset;
    cursor: pointer;
    gap: 0.25rem;

    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &:hover,
  &__content:focus {
    background-color: $active-bg-color;
  }

  &:hover &__content,
  &:focus {
    #{$block}__status-icon {
      filter: unset;
      color: $active-color;
    }

    #{$block}__title {
      color: $active-color;
    }
  }
}
</style>

import { Tab } from "../app-tab.vue";
import { Tabs } from "../app-tabs.vue";

/**
 * Move a tab from one position to another.
 * @param appTabs list of tabs
 * @param from - current index of the tab
 * @param to - new index of the tab
 */
export const moveTab = (appTabs: Array<Tab>, from: number, to: number) => {
  const newTabs = [...appTabs];
  newTabs.splice(from, 1);
  newTabs.splice(to, 0, appTabs[from]);
  return newTabs;
};

/**
 * Move a tab to the right
 * @param appTabs
 * @param from
 */
export const moveTabRight = (appTabs: Array<Tab>, from: number) => {
  const to = from + 1;
  if (to >= appTabs.length) return appTabs; // no change
  return moveTab(appTabs, from, to);
};

/**
 * Move a tab to the left
 * @param appTabs
 * @param from
 */
export const moveTabLeft = (appTabs: Array<Tab>, from: number) => {
  const to = from - 1;
  if (to < 0) return appTabs; // no change
  return moveTab(appTabs, from, to);
};

/**
 * Remove a tab from the list and select another one if it was selected
 * @param appTabs list of tabs
 * @param id of the tab to remove
 * @returns Tabs - new list of tabs and selected tab
 */
export const removeTab = ({ tabs, selected }: Tabs, id: string) => {
  const index = getTabIndex(tabs, id);
  if (index === -1) return { tabs, selected }; // no change

  const newTabs = [...tabs];
  newTabs.splice(index, 1);

  if (newTabs.length === 0) {
    // We removed the last tab
    return { tabs: [], selected: "" };
  }

  if (selected === tabs[index].id) {
    // We removed the selected tab, so we need to select another one...
    //   let's select the next one if there is one, else we select the previous one
    const newSelected = newTabs[index] || newTabs[index - 1];
    return { tabs: newTabs, selected: newSelected.id };
  }
  // We can keep the same selected tab
  return { tabs: newTabs, selected };
};

/**
 * Add a tab to the list
 * @param appTabs list of tabs
 * @param tab to add
 * @returns Tabs
 */
export const addTab = ({ tabs }: Tabs, tab: Tab) => ({
  tabs: [...tabs, tab],
  selected: tab.id,
});

/**
 * Update a tab
 * @param appTabs list of tabs
 * @param tab to update
 * @returns Tabs
 */
export const updateTab = ({ tabs, selected }: Tabs, tab: Tab) => ({
  tabs: tabs.map((t) => (t.id === tab.id ? tab : t)),
  selected,
});

/**
 * Select a tab by id
 * @param appTabs list of tabs
 * @param id of the tab to select
 * @returns Tabs
 */
export const selectTab = (appTabs: Tabs, id: string) => {
  const { tabs } = appTabs;
  const index = getTabIndex(tabs, id);
  if (index === -1) return appTabs; // no change
  return {
    ...appTabs,
    selected: tabs[index].id,
  };
};

/**
 * Select the next tab if there is one.
 * @param appTabs
 * @returns Tabs
 */
export const selectNextTab = (appTabs: Tabs) => {
  const nextTab = getNextTab(appTabs);
  const newTabs: Tabs = selectTab(appTabs, nextTab.id);
  return newTabs;
};

/**
 * Select the previous tab if there is one.
 * @param appTabs
 * @returns Tabs
 */
export const selectPreviousTab = (appTabs: Tabs) => {
  const previousTab = getPreviousTab(appTabs);
  const newTabs: Tabs = selectTab(appTabs, previousTab.id);
  return newTabs;
};

/**
 * Get the next tab if there is one. Else return the current tab
 * @param appTabs
 * @returns Tab
 */
const getNextTab = (appTabs: Tabs) => {
  const { tabs, selected } = appTabs;
  const selectedTabIndex = getTabIndex(tabs, selected);
  const nextTab = tabs[selectedTabIndex + 1];
  return nextTab || tabs[selectedTabIndex];
};

/**
 * Get the previous tab if there is one. Else return the current tab
 * @param appTabs
 * @returns Tab
 */
const getPreviousTab = (appTabs: Tabs) => {
  const { tabs, selected } = appTabs;
  const selectedTabIndex = getTabIndex(tabs, selected);
  const previousTab = tabs[selectedTabIndex - 1];
  return previousTab || tabs[selectedTabIndex];
};

/**
 * Get the tab by id
 * @param appTabs
 * @param id
 */
export const getTab = (appTabs: Array<Tab>, id: string) => {
  return appTabs.find((tab) => tab.id === id);
};

/**
 * Get the index of the tab
 * @param appTabs
 * @param id
 */
export const getTabIndex = (appTabs: Array<Tab>, id?: string) => {
  return appTabs.findIndex((tab) => tab.id === id);
};

/**
 * Move the selected tab to the right
 * @param appTabs
 */
export const moveSelectedTabRight = ({ tabs, selected }: Tabs) => {
  const index = getTabIndex(tabs, selected);
  const newTabs = moveTabRight(tabs, index);
  return { tabs: newTabs, selected };
};

/**
 * Move the selected tab to the left
 * @param appTabs
 */
export const moveSelectedTabLeft = ({ tabs, selected }: Tabs) => {
  const index = getTabIndex(tabs, selected);
  const newTabs = moveTabLeft(tabs, index);
  return { tabs: newTabs, selected };
};

/**
 * Shuffle the tabs randomly (Fisher-Yates shuffle)
 * @param tabs
 * @param selected
 */
export const shuffleTabs = ({ tabs, selected }: Tabs) => {
  const newTabs = [...tabs];
  for (let i = newTabs.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newTabs[i], newTabs[j]] = [newTabs[j], newTabs[i]];
  }
  return { tabs: newTabs, selected };
};

/**
 * Sort the tabs by title
 * @param tabs
 * @param selected
 * @param ascending
 */
export const sortTabs = ({ tabs, selected }: Tabs, ascending = true) => {
  const newTabs = [...tabs];
  if (ascending) newTabs.sort((a, b) => a.title.localeCompare(b.title));
  else newTabs.sort((a, b) => b.title.localeCompare(a.title));
  return { tabs: newTabs, selected };
};
